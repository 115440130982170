* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  z-index: 1;
}
 
.background-image {
  background: url('https://rare-gallery.com/thumbs/4580148-keyboards-computer-mice-computer-window-desk.png') no-repeat
    center center/cover;
  min-height: 100dvh;
  background-attachment: fixed;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.contact-lnk li a {
  text-decoration: none;
  color: rgba(252, 98, 8, 1);
}

.contact-lnk {
  position: fixed;
  top: 40%;
  z-index: 2;
}

.contact-lnk ul {
  padding: 0px;
  transform: translate(-270px, 0);
}
.contact-lnk ul li {
  display: block;
  margin: 5px 25px;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(252, 98, 8, 1);
  width: 300px;
  text-align: right;
  padding: 10px;
  border-radius: 0 30px 30px 0;
  transition: all 1s;
}

.contact-lnk ul li:hover {
  transform: translate(110px, 0);
  background: rgba(0, 0, 0, 0.1);
}

.contact-lnk ul li:hover a {
  color: rgba(252, 98, 8, 1);
  font-weight: 600;
}

.contact-lnk ul li:hover i {
  color: #fff;
  background: rgba(252, 98, 8, 1);
  transform: rotate(360deg);
  transition: all 1s;
}

.contact-lnk ul li i {
  margin-left: 10px;
  color: rgba(252, 98, 8, 1);
  border-radius: 50%;
  padding: 10px;
  font-size: 20px;
}

.lnk-txt {
  font-size: 1.2rem;
}

.header {
  text-align: center;
  color: white;
  line-height: 5rem;
  height: 20vh;
  width: 100%;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header h1 {
  font-weight: 100;
}

.header span {
  font-weight: 100;
  margin: 0 5px;
  font-size: 2.5rem;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.skills {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 2rem;
  gap: 2rem 2rem;
  color: white;
  width: 100%;
}

.skills img {
  height: 25px;
}
/* Animation Descriptions */

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideToLeft {
  from {
    transform: translateX(0); /* Start from the current position */
  }
  to {
    transform: translateX(-100%); /* Slide to the left */
  }
}

@keyframes slideFromBottom {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Apply the animation to the header element */
.header .left-txt {
  animation: slideFromLeft 2s ease forwards;
}
.header .right-txt {
  animation: slideFromRight 2s ease forwards;
}

.project-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4rem 4rem;
  margin: auto;

  margin-top: 3rem;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 450px;
  gap: 1rem 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.card img {
  width: 90%;
  height: 40%;
  border-radius: 20px;
}

.card p {
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

.project-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.project-info {
  line-height: 1.8rem;
  padding: 0 1rem;
}

.project-btn {
  display: inline-block;
  background: radial-gradient(at center, rgba(244, 173, 48, 1), rgba(252, 98, 8, 1));
  padding: 0.6rem;
  border-radius: 20px;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
}

.project-btn:hover {
  background: radial-gradient(at center, rgba(252, 98, 8, 1), rgba(244, 173, 48, 1));
  color: #fff;
}

.contact-lnk-touchable {
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .content {
    flex-direction: column;
    font-size: 1.5rem;
    line-height: 3rem;
  }

  .header span {
    display: none;
  }

  .card{
    width: 500px;
  }
  .project-cards {
    gap: 2rem 2rem;
  }

  .contact-lnk {
    display: none;
  }

  .contact-lnk-touchable {
    position: absolute;
    bottom: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 0 4rem;
  }

  .contact-lnk-touchable a {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.6rem;
    border-radius: 20px;
    color: rgba(252, 98, 8, 1);
    cursor: pointer;
    text-decoration: none;
    font-weight: 500;
  }

  .contact-lnk-touchable a i {
    margin-left: 0.5rem;
  }

  .multiselect-autocomplete-card{
    margin-bottom: 8rem;
  }
}

@media screen and (max-width: 767px) {
  .content {
    flex-direction: column;
    font-size: 1.5rem;
    line-height: 3rem;
  }
  .header span {
    display: none;
  }

  .skills {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 2rem;
    gap: 1rem 2rem;
    color: white;
  }

  .skills img {
    height: 20px;
  }

  .contact-lnk {
    display: none;
  }

  .contact-lnk-touchable {
    position: absolute;
    bottom: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 0 0.5rem;
  }

  .contact-lnk-touchable a {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.3rem;
    border-radius: 20px;
    color: rgba(252, 98, 8, 1);
    cursor: pointer;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.8rem;
  }

  .contact-lnk-touchable a i {
    margin-left: 0.5rem;
  }

  .contact-lnk-touchable a .lnk-txt {
    font-size: 0.8rem;
  }

  .project-cards {
    gap: 2rem 0;
  }

  .card {
    width: 500px;
    height: 500px;
  }
  .multiselect-autocomplete-card {
    margin-bottom: 8rem;
  }
}
